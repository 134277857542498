<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>

			<div class="full-width ">

				<Search
					:search="item_search"
					:option="search_option"

					@change="getData"
					@click="getData"
					class="mt-10"
				>
					<select
						slot="add"
						v-model="item_search.send_type"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">전송 구분</option>
						<template
							v-for="(code, index) in codes.W001.items"
						>
							<option
								:key="code.total_code + '_' + index"
								:value="code.code_value"
							>{{ code.code_name }}</option>
						</template>
					</select>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
						</colgroup>
						<thead>
						<tr>
							<th>
								<input
									type="checkbox"
								/>
							</th>
							<th>소속</th>
							<th>요청 상점</th>
							<th>처리 계정</th>
							<th>지갑 구분</th>

							<th>전송 구분</th>
							<th>구분</th>
							<th>금액</th>
							<th>수수료</th>
							<th>TID</th>

							<th>메모</th>
							<th>등록일</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="item in list_history"
							:key="item.uid"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.agency_upper_name }}</td>
							<td>{{ item.shop_name }}</td>
							<td>{{ item.account_id }}</td>
							<td>{{ item.virtual_name }}</td>

							<td>{{ item.send_type_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.history_type_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.amount | makeComma }}원</td>
							<td>{{ item.fee | makeComma }}원</td>
							<td class="word-break">{{ item.tid }}</td>

							<td class="word-break text-left">
								{{ item.memo }}
							</td>
							<td>{{ item.wDate }}</td>
							<td>
								<button
									class="bg-identify pa-5-10"
									@click="getVirtualhistory(item)"
								>상세정보</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearch"
					></Pagination>
				</div>
				<Empty
					v-else
				></Empty>
			</div>
		</div>
	</div>
</template>

<script>

import Search from "../Layout/Search";
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";

export default {
	name: 'VirtualWalletHisotry'
	, props: ['user', 'codes', 'virtual_uid']
	, components: {Empty, Search, Pagination}
	, data: function(){
		return {
			program: {
				name: '가상계좌 정보'
				, top: true
				, title: true
				, bottom: false
			}
			, items: []
			, item_search: {
				virtual_uid: this.$route.params.idx
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'member_name'
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, send_type: ''
				, history_type: ''
				, sDate: this.$date.getLastDate(7, '-')
				, eDate: this.$date.getToday('-')
			}
			,search_option:{

				is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'member_id'}
					, { name: '이름', column: 'member_name'}
				]
				,select: [
					{ name: '구분', column: 'history_type', items: [
							{ name: '출금', column: '0'}
							,{ name: '입금', column: '1'}
						]
					}
				]
				, sDate: true
				, eDate: true
			}
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( ( item ) => {

				this.codes["W001"].items.filter( (status) => {
					if(item.send_type == status.code_index){
						item.send_type_name = status.code_name
					}
				})
				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.history_type_color = 'red'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						item.history_type_color = 'blue'
						break;
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletHistory'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
	}
	, async created() {
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>