<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>

			<div class="full-width ">

				<Search
					:search="item_search"
					:option="search_option"

					@change="getData"
					@click="getData"
					@toExcel="toExcel"
					class="mt-10"
				>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
							<col width="80px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />

							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />

							<col width="120px" />
							<col width="120px" />
						</colgroup>
						<thead>
						<tr>
							<th>
								<input
									type="checkbox"
								/>
							</th>
							<th>소속</th>
							<th>요청 상점</th>
							<th>처리 계정</th>
							<th>지갑 구분</th>

							<th>전송 구분</th>
							<th>구분</th>
							<th>금액</th>
							<th>수수료</th>
							<th>TID</th>

							<th>등록일</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="item in list_history"
							:key="item.uid"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.agency_upper_name }}</td>
							<td>{{ item.shop_name }}</td>
							<td>{{ item.account_id }}</td>
							<td>{{ item.virtual_name }}</td>

							<td>{{ item.send_type_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.history_type_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.amount | makeComma }}원</td>
							<td>{{ item.fee | makeComma }}원</td>
							<td class="word-break">{{ item.tid }}</td>

							<td>{{ item.wDate }}</td>
							<td>
								<button
									class="bg-identify pa-5-10"
									@click="onDetail(item)"
								>상세정보</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearch"
					></Pagination>
				</div>
				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>

		<PopupLayer
			v-if="is_on_detail"
		></PopupLayer>
	</div>
</template>

<script>

import Search from "../Layout/Search";
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
import Excel from "../../components/Excel";
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'VirtualWithdrawalHistory'
	, props: ['user', 'codes', 'date', 'virtual_uid']
	, components: {PopupLayer, Excel, Empty, Search, Pagination}
	, data: function(){
		return {
			program: {
				name: '가상계좌 정보'
				, top: true
				, title: true
				, bottom: false
			}
			, items: []
			, item_search: {
				virtual_uid: this.$route.params.idx
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'seller_info.shop_name'
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, send_type: '1'
				, history_type: '0'
				, sDate: this.$date.getLastDate(7, '-')
				, eDate: this.$date.getToday('-')
			}
			,search_option:{

				is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '상점명', column: 'seller_info.shop_name'}
					, { name: '아이디', column: 'account_id'}
					, { name: '이름', column: 'account_name'}
				]
				, sDate: true
				, eDate: true
				, is_excel: true
			}
			, is_excel: false
			, excel_data: {
				name: '입출금 내역'
				,header: [
					{ key: 0, name: '소속', column: 'agency_upper_name'}
					,{ key: 0, name: '요청상점', column: 'shop_name'}
					,{ key: 0, name: '처리계정', column: 'account_id'}
					,{ key: 0, name: '지갑구분', column: 'virtual_name'}
					,{ key: 0, name: '전송구분', column: 'send_type_name'}
					,{ key: 0, name: '구분', column: 'history_type_name'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '수수료', column: 'fee'}
					,{ key: 0, name: 'TID', column: 'tid'}
					,{ key: 0, name: '메모', column: 'memo'}
					,{ key: 0, name: '등록일', column: 'wDate'}
				]
				,content: null
			}
			, is_on_detail: false
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( ( item ) => {
				switch (item.send_type){
					case '0':
						item.send_type_name = '내부 전송'
						break;
					case '1':
						item.send_type_name = '은행 출금'
						break;
					case '2':
						item.send_type_name = '전송 수수료'
						break;
					case '3':
						item.send_type_name = '정산'
						break;
					case '4':
						item.send_type_name = '본사 수수료'
						break;
					case '5':
						item.send_type_name = '지사 수수료'
						break;
					case '6':
						item.send_type_name = '은행 바로 출금'
						break;
					case '7':
						item.send_type_name = '정산 가감'
						break;
				}
				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.history_type_color = 'red'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						item.history_type_color = 'blue'
						break;
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletHistory'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		, onDetail: function(item){
			this.item_detail = item
			this.is_on_detail = true
		}
	}
	, async created() {
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>