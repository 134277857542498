<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>
			<div class="full-width ">

				<Search
					:search="search"
					:option="search_option"
					:is_item="true"

					@change="getData"
					@click="getData"
					@toItem="onItem"
					@toExcel="toExcel"
					class="mt-10"
				>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
							<col width="80px" />
							<col width="150px" />
							<col width="150px" />
							<col width="150px" />
							<col width="150px" />
							<col width="auto" />

							<col width="150px" />
							<col width="250px" />
						</colgroup>
						<thead>
						<tr>
							<th>관리 번호</th>
							<th>소속</th>
							<th>아이디</th>
							<th>상점명</th>
							<th>지갑 구분</th>
							<th>지갑 주소</th>

							<th>잔고</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="(item, index) in account_list"
							:key="item.uid"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.agency_upper_name }}</td>
							<td>{{ item.seller_id }}</td>
							<td>{{ item.shop_name }}</td>
							<td>{{ item.virtual_name }}</td>
							<td class="word-break">{{ item.virtual_account }}</td>
							<td>{{ item.balance | makeComma }}원</td>

							<td class="text-right">

								<button
									v-if="item.account_id != user.account_id && item.virtual_account"
									class="bg-identify pa-5-10 mr-10 color-white"
									@click="postRefresh(item, index)"
								><v-icon small class="color-white">mdi-refresh</v-icon></button>

								<button
									v-if="item.account_id != user.account_id"
									class="bg-identify pa-5-10 mr-10"
									@click="onSend(item)"
								>송금</button>

								<button
									v-if="item.account_id != user.account_id"
									class="bg-identify pa-5-10 mr-10"
									@click="onReturn(item)"
								>회수</button>

								<button
									v-if="false"
									class="bg-identify pa-5-10"
									@click="getVirtualhistory(item)"
								>상세정보</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="search"

						@click="getSearch"
					></Pagination>
				</div>

				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<VirtualWithdrawal
			v-if="is_on_widthdrawal_balance"
			:user="user"
			:item_merchant="item_virtual"

			@cancel="is_on_widthdrawal_balance = false"
		></VirtualWithdrawal>

		<PopupLayer
			v-if="is_on_send"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-480 ma-auto pa-20 radius-20">
					<div class="justify-space-between items-center under-line">
						<h6>지갑 송금</h6>
						<button
							@click="is_on_send = false"
						><v-icon>mdi-close</v-icon></button>
					</div>
					<div class="">
						<table class="table  size-px-14 th-left">
							<col width="150px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>상점명</th>
								<td>{{ item_send.shop_name }}</td>
							</tr>
							<tr>
								<th>상점 잔액</th>
								<td>{{ item_send.balance | makeComma }}원</td>
							</tr>
							<tr
								v-if="false"
							>
								<th>송금 가능 금액</th>
								<td>{{ invalid_balance | makeComma }} 원</td>
							</tr>
							<tr>
								<th>메모</th>
								<td>
									<input v-model="item.memo" placeholder="메모" class="input-box" maxlength="50"/>
								</td>
							</tr>
							<tr>
								<th>송금 금액</th>
								<td>
									<div class="justify-space-between">
										<div>
											<input v-model="item.send_amount" class="input-box" type="number" :rules="$rules.max(item, 'send_amount', 8)"/>
										</div>
										<button
											class="btn-inline btn-primary"

											@click="postSend"
										>송금</button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupLayer
			v-if="is_on_return"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-480 ma-auto pa-20 radius-20">
					<div class="justify-space-between items-center under-line">
						<h6>잔고 회수</h6>
						<button
							@click="is_on_return = false"
						><v-icon>mdi-close</v-icon></button>
					</div>
					<div class="">
						<table class="table  size-px-14 th-left">
							<col width="150px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>상점명</th>
								<td>{{ item_return.shop_name }}</td>
							</tr>
							<tr>
								<th>상점 잔액</th>
								<td>{{ item_return.balance | makeComma }}원</td>
							</tr>
							<tr>
								<th>메모</th>
								<td>
									<input v-model="item.memo" placeholder="메모" class="input-box" maxlength="50"/>
								</td>
							</tr>
							<tr>
								<th>회수 금액</th>
								<td>
									<div class="justify-space-between">
										<div>
											<input v-model="item.return_amount" class="input-box" type="number" :rules="$rules.max(item, 'return_amount', 8)"/>
										</div>
										<button
											class="btn-inline btn-primary"
											:disabled="is_return"

											@click="postReturn"
										>회수</button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</PopupLayer>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"
			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "@/view/Layout/Empty";
import VirtualWithdrawal from "./VirtualWithdrawal";
import PopupLayer from "../Layout/PopupLayer";
import Excel from "@/components/Excel";

export default {
	name: 'VirtualAccountList'
	, props: ['user', 'codes', 'virtual_info', 'invalid_balance', 'date']
	, components: {Excel, PopupLayer, VirtualWithdrawal, Search, Pagination, Empty}
	, data: function(){
		return {
			program: {
				name: '가상계좌 정보'
				, top: true
				, title: true
				, bottom: false
			}
			, virtual_uid: this.$route.params.idx
			, search: {
				virtual_code: ''
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'seller_info.shop_name'
				, search_value: ''
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, is_wallet: '1'
			}
			,search_option:{

				is_cnt: true
				, is_excel: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '상점명', column: 'seller_info.shop_name'}
					, { name: '아이디', column: 'account_id'}
					, { name: '이름', column: 'account_name'}
				]
				,select: [
					{ name: '지갑 여부', column: 'is_wallet', items: [
							{ name: '지갑 보유', column: '1'}
							,{ name: '지갑 미보유', column: '0'}
						]
					}
				]
			}
			, item_virtual: {

			}
			, items: []
			, item_detail: {}
			, is_on_widthdrawal_balance: false
			, item_send: {

			}
			, is_on_send: false
			, send_amount: ''
			, is_on_send_confirm: false
			, item: {
				send_amount: ''
				, return_amount: ''
				, memo: ''
			}
			, item_return: {

			}
			, is_on_return: false
			, is_excel: false
			,excel_data: {
				name: '지갑 목록'
				,header: [
					{ key: 0, name: '소속', column: 'agency_upper_name'}
					,{ key: 0, name: '아이디', column: 'account_id'}
					,{ key: 0, name: '상점명', column: 'shop_name'}
					,{ key: 0, name: '지갑구분', column: 'virtual_name'}
					,{ key: 0, name: '잔고', column: 'balance'}
				]
				,content: null
			}
		}
	}
	, computed: {
		account_list: function(){
			return this.items.filter( (item) => {
				if(item.agency_type == 'A001001'){
					item.balance = Number(item.balance) + this.item_virtual.total_balance
				}
				return item
			})
		}
		, is_account: function(){
			let t = false
			if(this.mode == 'account'){
				t = true
			}
			return t
		}
		, is_send: function(){
			let t = true
			if(this.item.send_amount > 0 && this.invalid_balance >= this.item.send_amount){
				t = false
			}
			return t
		}
		, is_return: function(){
			let t = true
			if(this.item.return_amount > 0){
				t = false
			}
			return t
		}
	}
	, methods: {

		getVirtualInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getVirtualFeeMember'
					,data: {
						virtual_uid: this.virtual_uid
					}
				})

				if(result.success){
					this.item_virtual = result.data
					if(this.item_virtual.virtual_info){
						let d =  JSON.parse(this.item_virtual.virtual_info)
						for(const[key, value] of Object.entries(d)){
							this.$set(this.item_virtual, key, value)
						}
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(!page){
				page = 1
			}
			this.search.page = page
			this.getData()
		}
		, onItem: function(){
			this.item_info = { }
			this.is_modal_item = true
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		, toList: function(item){
			this.$bus.$emit('to', { name: 'VirtualAccountList', params: { idx: item.uid }})
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletList'
					,data: {
						virtual_uid: this.virtual_uid
						, page: this.search.page
						, list_cnt: this.search.list_cnt
						, search_type: this.search.search_type
						, search_value: this.search.search_value
						, is_wallet: this.search.is_wallet
					}
				})

				if(result.success){
					this.items = result.data.result
					this.search.tCnt = result.data.tCnt
					this.search_option.tCnt = result.data.tCnt
					this.$set(this.search, 'total_count', result.data.tCnt)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSend: function(item){
			this.item_send = item
			this.is_on_send = true
		}
		, onReturn: function(item){
			this.item_return = item
			this.is_on_return = true
		}
		, postSend: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSend'
					,data: {
						virtual_uid: this.virtual_uid
						, shop_uid: this.item_send.shop_uid
						, amount: this.item.send_amount
						, memo: this.item.memo
					}
				})

				if(result.success){
					this.is_on_send = false
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postReturn: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postReturn'
					,data: {
						virtual_uid: this.virtual_uid
						, shop_uid: this.item_return.shop_uid
						, amount: this.item.return_amount
						, memo: this.item.memo
					}
				})

				if(result.success){
					this.is_on_return = false
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postRefresh: async function(item, index){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postRefresh'
					,data: {
						virtual_uid: this.virtual_uid
						, shop_uid: item.shop_uid
						, wallet_uid: item.uid
						, account_id: item.account_id
					}
				})

				if(result.success){
					this.items[index].balance = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getVirtualInfo()
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>