import { render, staticRenderFns } from "./VirtualWithdrawalHistory.vue?vue&type=template&id=7f72171e"
import script from "./VirtualWithdrawalHistory.vue?vue&type=script&lang=js"
export * from "./VirtualWithdrawalHistory.vue?vue&type=script&lang=js"
import style0 from "./VirtualWithdrawalHistory.vue?vue&type=style&index=0&id=7f72171e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports