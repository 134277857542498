<template>
	<div
		class="full-height flex-column"
	>
		<div class="pa-10 bg-white">
			<div class="">
				<div class="justify-space-between items-center">
					<h6>가상계좌 정보</h6>
					<button
						@click="getData"
						class="mr-10"
					><v-icon class="color-blue">mdi-refresh</v-icon></button>
				</div>
				<table class="table">
					<colgroup>
						<col width="150px" />
						<col width="auto" />
						<col width="150px" />
						<col width="auto" />
						<col width="150px" />
						<col width="auto" />
					</colgroup>
					<tbody>
					<tr>
						<th>가상계좌</th>
						<td class="">{{ item_virtual.virtual_name }}</td>

						<th>계좌 번호</th>
						<td class=""> {{ item_virtual.virtual_account }}</td>
						<th>가상계좌 잔고</th>
						<td>
							<div
								class="justify-space-between items-center"
							>
								<span>
									{{ item_virtual.total_balance | makeComma}}원
									/
									{{ item_virtual.balance_amount | makeComma }}원
								</span>
								<button
									class="btn-inline btn-primary"
									@click="onMerchatWithdrawal"
								>송금</button>
							</div>
						</td>
					</tr>
					<tr>
						<th>MID</th>
						<td class=""> {{ item_virtual.mid }}</td>
						<th>지갑 주소</th>
						<td>
							<span>{{ item_virtual.wallet_account }}</span>
							<button
								v-if="!item_virtual.wallet_account"
								class="btn-inline btn-primary"
								@click="postWallet"
							>지갑 생성</button>
						</td>
						<th>수수료 잔고</th>
						<td>
							<div
								class="justify-space-between items-center"
							>
								{{ item_virtual.fee_db | makeComma }}원
								<button
									class="btn-inline btn-primary"
									@click="onWalletWithdrawal"
								>출금</button>
							</div>
						</td>
					</tr>
					<tr>
						<th></th>
						<td></td>
						<th></th>
						<td></td>
						<th>유저 잔고</th>
						<td class="text-left">
							{{ invalid_balance | makeComma }} 원

							<div class="mt-10"></div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="mt-30 justify-start tab ">
			<h6
				class="pa-10  "
				@click="mode = 'account'"
				:class="mode == 'account' ? 'bg-identify' : 'bg-white box'"
			>지갑 목록</h6>
			<h6
				class="pa-10 "
				@click="mode = 'history'"
				:class="mode == 'history' ? 'bg-identify' : 'bg-white box'"
			>입출금 내역</h6>
			<h6
				class="pa-10 "
				@click="mode = 'withdrawal'"
				:class="mode == 'withdrawal' ? 'bg-identify' : 'bg-white box'"
			>지갑 출금 내역</h6>
		</div>

		<div
			class="bg-white pa-10 full-height overflow-y-auto"
		>
			<VirtualAccountList
				v-if="mode == 'account'"
				:user="user"
				:codes="codes"
				:virtual_info="item_virtual"
				:invalid_balance="item_virtual.total_balance"
			></VirtualAccountList>

			<VirtualWalletHisotry
				v-else-if="mode == 'history'"
				:user="user"
				:codes="codes"
			></VirtualWalletHisotry>

			<VirtualWithdrawalHistory
				v-else-if="mode == 'withdrawal'"

				:user="user"
				:codes="codes"
			></VirtualWithdrawalHistory>

		</div>

		<VirtualWithdrawal
			v-if="is_on_virtual_widthdrawal"
			:user="user"
			:item_merchant="item_virtual"

			@click="is_on_virtual_widthdrawal = false; getData()"
			@cancel="is_on_virtual_widthdrawal = false"
		></VirtualWithdrawal>

		<WalletWithdrawal
			v-if="is_on_wallet_widthdrawal"
			:user="user"
			:item_merchant="item_virtual"

			@click="is_on_wallet_widthdrawal = false; getData()"
			@cancel="is_on_wallet_widthdrawal = false"
		></WalletWithdrawal>
	</div>
</template>

<script>

import VirtualWithdrawal from "./VirtualWithdrawal";
import VirtualAccountList from "./VirtualAccountList";
import WalletWithdrawal from "./WalletWithdrawal";
import VirtualWalletHisotry from "./VirtualHistory";
import VirtualWithdrawalHistory from "./VirtualWithdrawalHistory";

export default {
	name: 'VirtualInfo'
	, props: ['user', 'codes']
	, components: {
		WalletWithdrawal,
		VirtualWithdrawal, VirtualWithdrawalHistory, VirtualWalletHisotry, VirtualAccountList}
	, data: function(){
		return {
			program: {
				name: '가상계좌 정보'
				, top: true
				, title: true
				, bottom: false
			}
			, item_virtual: {

			}
			, mode: 'account'
			, is_on_virtual_widthdrawal: false
			, is_on_wallet_widthdrawal: false
		}
	}
	, computed: {
		invalid_balance: function(){
			let t = 0
			t = Number(this.item_virtual.settlement_db)
				+ Number(this.item_virtual.deposit_db)
				- Number(this.item_virtual.minus_db)
				- Number(this.item_virtual.withdrawal_db)
			return t
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getVirtualInfo'
					,data: {
						virtual_uid: this.$route.params.idx
					}
				})

				if(result.success){
					this.item_virtual = result.data
					if(this.item_virtual.virtual_info){
						let d =  JSON.parse(this.item_virtual.virtual_info)
						for(const[key, value] of Object.entries(d)){
							this.$set(this.item_virtual, key, value)
						}
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postWallet: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postWallet'
					,data: {
						virtual_uid: this.$route.params.idx
					}
				})

				if(result.success){
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMerchatWithdrawal: function(){
			this.is_on_virtual_widthdrawal = true
		}
		, onWalletWithdrawal: function(){
			this.is_on_wallet_widthdrawal = true
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>